.ReactModal__Overlay--after-open{
    background-color: #113b0198 !important;
    z-index: 1000;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    transition: 3000;
}

.ReactModal__Content--after-open{
    width: 50%;
    height: 70%;
    position: initial !important;
    display: flex;
    flex-direction: column;
    border: #013b23 solid 5px !important;
}

.ReactModal__Body--open{
    overflow: hidden!important;
}
.btnVolver{
    position: unset !important;
    align-self: end;
    background-color: #013b23;
    color: #ffffff;
    border: none;
    padding: 10px;
}

.imgTratamiento{
    width: -webkit-fill-available;
}

.modalContainer{
    color: #013b23;
}

.parrafoModal{
    font-size: 1vw;
}

.tituloModal{
    font-size: 1.5vw;
}
:root{
    --principal: #013b23;
    --second: #ffffff;
}

h2{
    font-size: 4vw;
    font-weight:400;
    margin: unset;
    margin-top: 2vh;
}

.quienesContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--principal);
    color: var(--second);
}

.QuienesParrafoContainer{
    width: 45%;
}
.quienesTitulo{
    margin-top: 10vh;
}
.quienes{
    font-weight: 100;
    font-size: 1.3vw;
}
.quienesFinal{
    margin-bottom: 10vh;
}
span{
    font-weight: 600;
}

.imgSeparador{
    width: 100vw;
}

.separadorContainer{
    display: flex;
}